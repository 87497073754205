:root {
  --textBoxDisplay: initial;
  --textBoxBorderHoverColor: #ed1c2480;
  --textBoxFontSize: 1em;
  --colorBackground: #c4c3d0;
  --color1: #f9f9fb;
  --color2: #e2e2e9;
  --color3: #071013;
  --color3a: #0710134d;
  --color3b: #0710131a;
  --color4: #028090;
  --color4a: #366ea7;
  --color4b: #cddae9;
  --color5: #80ed99;
  --color6: #f25757;
}

body {
  background-color: var(--colorBackground);
  color: var(--color3);
  margin: 0;
  font-family: Open Sans, sans-serif;
  overflow: hidden;
}

a {
  color: var(--color4);
  text-decoration: none;
}

a:hover {
  color: var(--color5);
}

.colorRed {
  color: var(--color6);
}

.notransition {
  transition: none !important;
}

.pageContainer * {
  font-family: Noto Sans JP, Meiryo, MS Gothic, sans-serif;
}

.pageContainer {
  margin: 0 auto;
  position: relative;
}

.pageContainer:hover .textBox {
  border: 2px solid var(--textBoxBorderHoverColor);
}

.textBox {
  display: var(--textBoxDisplay);
  white-space: nowrap;
  border: 1px solid #0000;
  padding: 0;
  font-size: 16pt;
  line-height: 1.1em;
  position: absolute;
}

.textBox:hover, .textBox.selected {
  background: #fff;
  border: 1px solid #0000;
  z-index: 999 !important;
}

.textBox p {
  white-space: nowrap;
  letter-spacing: .1em;
  background-color: #fff;
  margin: 0;
  line-height: 1.1em;
  display: none;
}

.textBoxFontSizeOverride .textBox p {
  font-size: var(--textBoxFontSize) !important;
}

.textBox:hover p, .textBox.selected p {
  display: table;
}

#pagesContainer {
  flex-direction: row;
  display: inline-flex;
  overflow: visible;
}

#pagesContainer.inverted {
  filter: invert();
}

.page {
  float: left;
  margin: 0 -1px 0 0;
  display: none;
}

#leftAPage, #rightAPage, #leftAScreen, #rightAScreen {
  z-index: 1;
  background-color: #0000;
}

#leftAPage, #rightAPage {
  width: 10%;
  height: 110%;
  display: none;
  position: absolute;
  top: -5%;
}

#leftAPage {
  left: -7%;
}

#rightAPage {
  right: -7%;
}

#leftAScreen, #rightAScreen {
  width: 5vw;
  height: 90vh;
  display: inline-block;
  position: fixed;
  top: 10vh;
}

#leftAScreen {
  left: 0;
}

#rightAScreen {
  right: 0;
}

#showMenuA {
  z-index: 1000;
  background-color: #0000;
  width: 3em;
  height: 3em;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#topMenu {
  z-index: 1000;
  background: var(--color1);
  box-shadow: 0px 0px 8px 0px var(--color3a);
  visibility: visible;
  opacity: 1;
  white-space: nowrap;
  border-radius: 3px;
  max-width: 100vw;
  margin: 5px;
  transition: all .5s ease-out, max-width ease-in;
  position: fixed;
  top: 0;
  left: 0;
}

#topMenu.hidden {
  visibility: hidden;
  opacity: 0;
  max-width: 2.5em;
  transition: all 1s ease-in, max-width .5s ease-out;
  overflow: hidden;
}

#topMenu * {
  vertical-align: middle;
  font-size: min(1em, 3.5vw);
}

#topMenu input {
  height: 1.4em;
  margin: 0 6px;
}

#pageIdxDisplay {
  margin-left: .5em;
  margin-right: .5em;
}

.menuButton {
  color: var(--color3);
  background-color: #0000;
  border: none;
  border-radius: 3px;
  width: 2.5em;
  height: 2.2em;
}

.menuButton svg {
  max-height: 1.5em;
}

.menuButton:hover {
  background-color: var(--color2);
}

.popupTitle {
  margin: 10px 10px 10px 40px;
  font-size: 1.5em;
}

.popup hr {
  border-top: 1px solid var(--color3a);
  margin: 0 20px;
}

.popupCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.dropdown:hover #dropbtn {
  background-color: var(--color2);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  background-color: var(--color1);
  box-shadow: 0px 0px 8px 0px var(--color3a);
  z-index: 1000;
  border-radius: 3px;
  max-height: 90vh;
  display: none;
  position: absolute;
  right: 0;
  overflow: hidden auto;
}

.dropdown-content .buttonRow {
  display: flex;
}

.dropdown-content .menuButton {
  flex: auto;
  align-self: center;
}

.dropdown-content .dropdown-option {
  color: var(--color3);
  white-space: nowrap;
  border-radius: 3px;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.dropdown-content .dropdown-option:hover {
  background-color: var(--color2);
}

.dropdown-content .dropdown-option [type="checkbox"] {
  vertical-align: middle;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#statusBar {
  z-index: 1000;
  background: var(--color1);
  box-shadow: 0px 0px 8px 0px var(--color3a);
  visibility: visible;
  opacity: 1;
  border-radius: 3px;
  max-width: 100vw;
  margin: 5px;
  padding: 0;
  font-size: 1em;
  transition: all .5s ease-out;
  position: fixed;
  bottom: 0;
  right: 0;
}

#statusBar p {
  margin: 1px;
}

#dimOverlay {
  z-index: 1001;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.popup {
  z-index: 1002;
  background-color: var(--color1);
  color: var(--color3);
  box-shadow: 0px 0px 10px 4px var(--color3a);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 4px;
  line-height: 2em;
  display: none;
  position: fixed;
  overflow: auto;
}

.popupContent {
  margin: 20px;
}

.popupSmall {
  width: min(720px, 96vw);
  height: min(640px, 96vh);
  margin-top: max(-320px, -48vh);
  margin-left: max(-360px, -48vw);
  top: 50vh;
  left: 50vw;
}

.popupLarge {
  width: min(96vw, 800px);
  height: min(96vh, 800px);
  margin-top: max(-48vh, -400px);
  margin-left: max(-48vw, -400px);
  top: 50vh;
  left: 50vw;
}

#settingsDisplay select, .selectOption {
  height: 100%;
}

.popup table {
  background-color: var(--color1);
  color: var(--color3);
  width: 96%;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
}

.settingsLeftCol {
  width: 50%;
}

.popup th {
  background-color: var(--color1);
  overflow-wrap: anywhere;
  text-align: left;
  font-size: .75em;
  font-weight: 100;
}

.popup td {
  vertical-align: middle;
  padding: 8px;
  font-size: .75em;
}

.settingsDivider {
  float: left;
  vertical-align: middle;
  text-align: center;
  padding: 4px 16px;
}

.selectOption, .colorOption, .resetButton {
  float: left;
  vertical-align: middle;
  height: 2em;
}

.popup tr:hover {
  background-color: var(--color2);
}

.toggleOption {
  float: left;
  background: var(--color2);
  border: 1px solid var(--color3a);
  white-space: nowrap;
  border-radius: 8px;
  overflow: hidden;
}

.toggleOption input {
  opacity: 0;
  position: absolute;
}

.toggleOption input + label {
  float: left;
  color: var(--color3);
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  min-width: 2em;
  padding: 4px;
}

.toggleOption input:checked + label {
  color: var(--color1);
  background: var(--color4a);
}

.alignright {
  text-align: right;
}

.alignleft {
  text-align: left;
}

#catalogDisplay {
  background-color: var(--color1);
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  inset: 60px 0 min(11em, 35%);
  overflow: auto;
}

#catalogBottomMenu {
  text-align: center;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: .75em;
  display: flex;
  position: absolute;
  bottom: min(10em, 25%);
  left: 0;
  right: 0;
}

#catalogBottomMenu div {
  margin: 1em 0;
}

#dropbox {
  border: 2px var(--color3a) dashed;
  background-color: var(--color1);
  box-shadow: 0px 0px 10px 1px var(--color3b);
  border-radius: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  height: min(6em, 20%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;
  bottom: 2em;
  left: 0;
  right: 0;
}

#dropbox.isDragover {
  background-color: var(--color2);
}

#dropbox p {
  margin: 4px 1em;
  line-height: 1.5em;
}

.smallText {
  font-size: .75em;
}

.volumeStats, .volumeStatus, .volumeUrl {
  color: var(--color3);
  margin-left: .5em;
  font-size: .75em;
  line-height: 1em;
}

.deleteCatalogItemButton {
  margin-left: .5em;
  display: none;
}

.catalogItem {
  width: 100%;
  display: block;
}

.catalogItem:hover .deleteCatalogItemButton {
  display: initial;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/*# sourceMappingURL=index.f466a4fa.css.map */
